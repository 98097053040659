<template>
  <div class="video">
    <ProjectMenu
      class="project-menu-area"
      v-show="projectMenuShow"
      :hiddenFun="hiddenProjectMenu"
      :stopFun="stopFun"
      :menuList="menuList"
    />
    <div class="help">
      <div class="top">
        <TopBar
          @menuMouseover="menuMouseover"
          @menuMouseleave="menuMouseleave"
          ref="topCom"
          :isBlack="true"
          :userInfo="userInfo"
        />
      </div>
      <div class="help-middle-area">
        <div class="centerContent">
          <div class="banner">
            <div class="bannercenter">
              <div class="card">
                <div class="scene">视频专区</div>
                <div class="u"></div>
                <p>跟着视频演示更快玩转产品</p>
              </div>
            </div>
          </div>
          <div class="search">
            <div class="searchCenter">
              <div class="searchLeft">
                <div class="squire"></div>
                <span>产品类型</span>
              </div>
              <div class="searchRight">
                <el-input
                  clearable
                  class="video-search"
                  placeholder="请输入相关视频关键字"
                  prefix-icon="el-icon-search"
                  v-model="params.videoName"
                  @change="getVideoList"
                >
                  <template slot="append">
                    <el-button class="searchButton" @click="getVideoList">搜索</el-button>
                  </template>
                </el-input>
              </div>
            </div>
          </div>
          <div class="tabCard">
            <div class="tabCenter">
              <ul>
                <li
                  v-for="(item, index) in platformList"
                  :key="item.id"
                  @click="changeProgram(index, item.id)"
                  :class="isActive === item.id ? 'active-platform':'not-active-platform'"
                >{{ item.name }}</li>
              </ul>
            </div>
          </div>
          <div v-if="videoList.length" class="videoList">
            <div class="videoCenter">
              <div class="videoCard" v-for="(item, index) in videoList" :key="item.videoId">
                <div class="topVideo">
                  <img :src="item.imgUrl" alt v-if="item.imgUrl" />
                  <div class="imgMask" v-else></div>
                  <div class="masking">
                    <div
                      class="iconfont"
                      @click="itemClick(item.videoUrl, item.videoName, item.imgUrl, index)"
                    >
                      <i class="el-icon-video-play"></i>
                    </div>
                    <div class="video-time">{{ item.videoTime }}</div>
                  </div>
                </div>

                <div class="video-desc">
                  <p v-if="isLong(item.videoName)">
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="item.videoName"
                    placement="top-start"
                  >
                    <h4>{{ item.videoName }}</h4>
                  </el-tooltip>
                  </p>
                  
                  <h4 v-else>{{ item.videoName }}</h4>

                  <p>{{ item.modifiedDate }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-tip">
            <div class="empty-tip-pic"></div>
            <p class="empty-tip-words">视频飞走了,赶快去找找呦~</p>
          </div>
        </div>
      </div>
      <div class="help-bottom-area">
        <Bottom />
      </div>
    </div>
    <!-- 视频播放 -->
    <el-dialog
      :title="videoName"
      :visible.sync="centerDialogVisible"
      destroy-on-close
      :close-on-click-modal="false"
      ref="vueRef"
      width="1200px"
      height="640px"
    >
      <div class="video-wrap">
        <div id="video"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findAll2 } from '@/server/api'
import TopBar from '@/components/TopBar.vue'
import ProjectMenu from '@/components/ProjectMenu.vue'
import Bottom from './component/Bottom.vue'
import Mp4Player from 'xgplayer-mp4'

export default {
  data() {
    return {
      cur: 0,
      projectMenuShow: false,
      centerDialogVisible: false,
      videoUrl: '',
      videoName: '',
      platformList: [],
      videoList: [],
      params: {
        videoName: '',
        programId: ''
      },
      pageInfo: {
        pageIndex: 1,
        pageSize: 1000
      },
      isActive: -1,
      menuList: [],
      userInfo: {}
    }
  },
  components: {
    TopBar,
    ProjectMenu,
    Bottom
  },
  async mounted() {
    const result = await findAll2()
    this.menuList = result.data.rspData
  },
  computed: {
    isLong() {
      return function(val) {
        if (val.length > 10) {
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    
    stopFun() {
      clearTimeout(this.timer)
    },
    menuMouseover(code) {
      if (code === 'produce') {
        this.projectMenuShow = true
      }
    },
    menuMouseleave(code) {
      if (code === 'produce') {
        this.timer = setTimeout(() => {
          this.projectMenuShow = false
          this.$refs.topCom.selectedProduce(false)
        }, 200)
      }
    },
    hiddenProjectMenu() {
      this.projectMenuShow = false
      this.$refs.topCom.selectedProduce(false)
    },
    itemClick(videoUrl, videoName, imgUrl) {
      this.videoUrl = ''
      this.videoName = ''
      this.videoUrl = videoUrl
      this.videoName = videoName
      this.centerDialogVisible = true
      this.$nextTick(() => {
        new Mp4Player({
          id: 'video',
          url: videoUrl,
          width: 1152,
          height: 618,
          loop: true,
          poster: imgUrl,
          playbackRate: [0.5, 0.75, 1, 1.5, 2],
          defaultPlaybackRate: 1
        })
      })
    },
    //获取programId
    changeProgram(index, id) {
      if (index === 0) {
        this.isActive = -1
        this.params.programId = ''
        this.params.videoName = ''
      } else {
        this.params.programId = this.isActive = id
      }
      this.getVideoList()
    },
    //获取分类标签
    getTabList() {
      this.$http.get('/api/program/find/allV2').then(res => {
        this.platformList = res.data.rspData
        this.platformList.unshift({
          name: '全部',
          id: -1
        })
      })
    },
    //获取视频文件
    getVideoList() {
      this.$http
        .post('/api/support/help/video/query', {
          params: {
            videoName: this.params.videoName,
            programId: this.params.programId
          },
          pageIndex: this.pageInfo.pageIndex,
          pageSize: this.pageInfo.pageSize
        })
        .then(res => {
          this.videoList = res.data.rspData.records.filter(
            elem => elem.dataStatus === 1
          )
          // 获取时间
          for (let i = 0; i < this.videoList.length; i += 1) {
            if (!this.videoList[i].videoTime) this.videoList[i].videoTime = 0
            let videoTime = this.videoList[i].videoTime
            videoTime = parseInt(videoTime)
            let min =
              parseInt(videoTime / 60) >= 10
                ? parseInt(videoTime / 60)
                : `0${parseInt(videoTime / 60)}`
            let seconds =
              parseInt(videoTime % 60) >= 10
                ? videoTime % 60
                : `0${videoTime % 60}`
            this.videoList[i].videoTime = `${min}:${seconds}`
          }
        })
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getVideoList()
    this.getTabList()
  }
}
</script>
<style lang='scss' scoped>
::v-deep {
  .el-input__inner {
    color: #393f49;
  }
  .el-input__inner:focus {
    border-color: #c9042a;
  }
  .el-dialog .el-dialog__header {
    position: relative;
    padding: 0 24px;
    margin: 0;
    height: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 56px;
    border-bottom: 1px solid rgb(227, 229, 236);
  }
  .el-dialog__body {
    padding: 24px;
    font-size: 12px;
  }
}
.video {
  height: 100%;
}
.video-wrap {
  width: 1152px;
  height: 618px;
  background-color: rgba(0, 0, 0, 0);

  #video {
    width: 1152px;
    height: 618px;
    object-fit: cover;
    display: block;
  }
}

.centerContent {
  overflow: hidden;
  flex: 1;
}

.help {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.top {
  overflow: hidden;
}
.help-middle-area {
  flex: 1;
}
.help-bottom-area {
  flex: 0 0 88px;
}

.searchRight {
  overflow: hidden;
  ::v-deep {
    .el-input {
      border-radius: 2px;
      width: 99%;
    }
    .el-input-group__append {
      vertical-align: -1px;
      border-color: #c9042a;
    }
    .el-button {
      border-radius: 0;
    }
  }
  .searchButton {
    background: #c9042a;
    color: #fff;
  }
}

.banner {
  height: 240px;
  background-size: 100% 100%;
  background-image: url(../assets/helpImg/videoBanner.png);
  padding-top: 0;

  .bannercenter {
    width: 62.5%;
    margin: 0 auto;
    height: 240px;
    position: relative;

    .card {
      position: absolute;
      left: 0;
      top: 64px;
      width: 330px;
      height: 142px;
      box-sizing: border-box;
      padding-left: 30px;
      padding-top: 30px;
      background: rgba(255, 255, 255, 0.68);

      .scene {
        font-size: 26px;
        margin-bottom: 16px;
      }

      .u {
        width: 124px;
        height: 1px;
        background: red;
      }

      p {
        margin-top: 16px;
        font-size: 16px;
        color: #858a94;
      }
    }
  }
}

.empty-tip {
  margin-top: 76px;
  text-align: center;
  color: #393f49;
  .empty-tip-pic {
    display: inline-block;
    width: 80px;
    height: 80px;
    background: url('../assets/helpImg/empty-video-tip.png') no-repeat center
      center;
    background-size: 100%;
  }
  .empty-tip-words {
    margin-top: 20px;
    font-size: 14px;
    color: #858a94;
  }
}

.search {
  border-bottom: 1px solid #f4f5f9;

  .searchCenter {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;

    .searchLeft {
      height: 26px;
      font-size: 18px;
      font-weight: bold;
      color: rgba(57, 63, 73, 1);
      display: flex;
      align-items: center;

      .squire {
        width: 4px;
        height: 20px;
        background: rgba(201, 4, 42, 1);
        margin-right: 14px;
      }

      span {
        font-size: 18px;
        color: #393f49;
      }
    }

    .searchRight {
      width: 455px;
      display: flex;
    }
  }
}

.tabCard {
  .tabCenter {
    width: 1200px;
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 22px;

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        background: rgba(244, 245, 249, 1);
        list-style: none;
        padding-left: 14px;
        padding-right: 14px;
        font-size: 14px;
        cursor: pointer;
        color: #393f49;
        margin-right: 10px;
        margin-bottom: 10px;

        &.active-platform {
          color: #c9042a;
          font-weight: 500;
        }

        &:hover {
          color: #c9042a;
        }
      }
    }
  }
}

.videoList {
  margin-bottom: 30px;
  overflow: hidden;

  .videoCenter {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .videoCard {
      width: 285px;
      margin-right: 20px;
      margin-bottom: 20px;

      ::v-deep {
        .el-dialog__body {
          padding: 15px 20px 20px 20px !important;
        }
      }

      cursor: pointer;

      .topVideo {
        position: relative;

        .masking {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          background: rgba(10, 18, 35, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;

          .iconfont {
            width: 36px;
            height: 36px;
            z-index: 999;
            font-size: 36px;
            color: #dadbde;
          }

          .video-time {
            position: absolute;
            bottom: 6px;
            right: 6px;
            width: 54px;
            height: 18px;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            background: rgba(10, 18, 35, 0.72);
            border-radius: 9px;
            color: #fff;
          }
        }
      }

      .imgMask {
        width: 100%;
        height: 160px;
      }

      img {
        display: block;
        width: 100%;
        height: 160px;
      }

      .video-desc {
        height: 60px;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 8px 0 rgba(224, 227, 237, 1);
        margin-right: 20px;
        z-index: 9999;

        h4 {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
          color: #393f49;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          font-size: 12px;
          line-height: 18px;
          color: #393f49;
        }
      }
    }
    div.videoCard:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}

.el-dialog {
  visibility: hidden;
}
.video-search ::v-deep .el-input-group__append {
  background: #c9042a;
}
</style>
